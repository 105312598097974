import _ from 'lodash';
import { isDependant } from '../../../entities/recipients/shared';
import { TShipmentType } from '../../../entities/shipments/queries';
import { TDependantType, TGiftTypes, TWorkAnniversaryGiftVariations } from '../../../generated/graphql';
const NEW_HIRE_KIT = [
  {
    value: 48,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
];

const SMALL_PLAN_WORK_ANNIVERSARY = [
  {
    value: 10,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 10,
    hsCode: '3406.00',
    quantity: 1,
    description: 'Candle',
    country: 'CHN',
  },
  {
    value: 4,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Cookies',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1806.20',
    quantity: 1,
    description: 'Chocolates',
    country: 'CAN',
  },
  {
    value: 2,
    hsCode: '3605.00',
    quantity: 1,
    description: 'Matches',
    country: 'CHN',
  },
];

const LARGE_PLAN_WORK_ANNIVERSARY = [
  {
    value: 10,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 10,
    hsCode: '3406.00',
    quantity: 1,
    description: 'Candle',
    country: 'CHN',
  },
  {
    value: 4,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Cookies',
    country: 'CAN',
  },
  {
    value: 1.5,
    hsCode: '1806.20',
    quantity: 2,
    description: 'Chocolates',
    country: 'CAN',
  },
  {
    value: 2,
    hsCode: '3605.00',
    quantity: 1,
    description: 'Matches',
    country: 'CHN',
  },
  {
    value: 5,
    hsCode: '0406.30',
    quantity: 1,
    description: 'Shelf Stable Cheese',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1905.90',
    quantity: 1,
    description: 'Crackers',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2007.10',
    quantity: 1,
    description: 'Mini jar of Jam',
    country: 'CAN',
  },
];

const BRICK_ONLY_WORK_ANNIVERSARY = [
  {
    value: 3,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego Brick',
    country: 'DNK',
  },
];

const EMPLOYEE_BIRTHDAY = [
  {
    value: 6,
    hsCode: '8609.00',
    quantity: 1,
    description: 'Glass container',
    country: 'CHN',
  },
  {
    value: 3,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Gummies',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Pretzels',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Candy popcorn',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2008.19',
    quantity: 1,
    description: 'Nuts',
    country: 'CAN',
  },
];

const SPOUSE_BIRTHDAY = [
  {
    value: 5,
    hsCode: '4420.11',
    quantity: 1,
    description: 'Wood bowl',
    country: 'CHN',
  },
  {
    value: 3,
    hsCode: '1905.90',
    quantity: 1,
    description: 'Crackers',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Candy popcorn',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2005.20',
    quantity: 1,
    description: 'Chips',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2103.90',
    quantity: 1,
    description: 'Dip',
    country: 'CAN',
  },
];

const customsMapping = {
  NEW_HIRE_KIT,
  SMALL_PLAN_WORK_ANNIVERSARY,
  LARGE_PLAN_WORK_ANNIVERSARY,
  BRICK_ONLY_WORK_ANNIVERSARY,
  EMPLOYEE_BIRTHDAY,
  SPOUSE_BIRTHDAY,
};

export const getDefaultCustoms = (shipments: TShipmentType[]) => {
  let giftTypes: (keyof typeof customsMapping)[] = [];
  for (const shipment of shipments) {
    const company = shipment.company;
    for (const gift of shipment.gifts || []) {
      if (gift.type === TGiftTypes.NewHire) {
        giftTypes.push('NEW_HIRE_KIT');
      } else if (gift.type === TGiftTypes.WorkAnniversary) {
        if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.BrickOnly) {
          giftTypes.push('BRICK_ONLY_WORK_ANNIVERSARY');
        } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Deluxe) {
          giftTypes.push('LARGE_PLAN_WORK_ANNIVERSARY');
        } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Mini) {
          giftTypes.push('SMALL_PLAN_WORK_ANNIVERSARY');
        }
      } else if (gift.type === TGiftTypes.Birthday) {
        const recipient = gift.recipient;

        if (recipient) {
          if (isDependant(recipient)) {
            if (recipient.type === TDependantType.SignificantOther) {
              giftTypes.push('SPOUSE_BIRTHDAY');
            }
          } else {
            giftTypes.push('EMPLOYEE_BIRTHDAY');
          }
        }
      }
    }
  }

  giftTypes = _.uniq(giftTypes);

  if (giftTypes.length === 1) {
    return customsMapping[giftTypes[0]];
  }

  return undefined;
};
